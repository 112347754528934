<!--
 * @Author: DY
 * @Date: 2020-05-27 14:52:13
 * @LastEditTime: 2020-08-10 17:24:49
 * @LastEditors: Please set LastEditors
 * @Description: 企业大屏
 * @FilePath: \JT_Web_dev\src\views\enterpriseScreen\index.vue
-->
<template>
    <div class="enterpriseScreen">
        <back></back>
        <header>
            <span @click="centerDialogVisible = true">济宁中联大屏展示平台</span>
        </header>
        <section>
            <div class="el_left content_l">
                <div class="dailyData">
                    <div class="title">
                        <h4>每日数据</h4>
                    </div>
                    <div class="header">
                        <div class="el_left time iconfont iconbelow-s">
                            <el-date-picker
                                v-model="dailyDataTime"
                                type="date"
                                placeholder="选择日期"
                            >
                            </el-date-picker>
                        </div>
                        <div class="el_right type">
                            <el-radio-group v-model="dailyDataRadio">
                                <el-radio :label="0">
                                    混凝土
                                </el-radio>
                                <el-radio :label="1">
                                    砂浆
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="total">
                        <div>
                            <h4>日销量：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="dailySalesVolume"></p>
                            <span> 方</span>
                        </div>
                        <div>
                            <h4>日均价：</h4>
                            <p class="scrollNumbers scrollNumbers1" id="averageDailyPrice"></p>
                            <span> 元/方</span>
                        </div>
                    </div>
                    <div class="chartTitle">
                        <span>日销量+日均价</span>
                    </div>
                    <div class="charts" id="dailySalesVolumeChart"></div>
                    <div class="chartTitle">
                        <span>近一周变化趋势</span>
                        <button>更多<i class="iconfont iconjiantouyou"></i></button>
                    </div>
                    <div class="charts" id="weekTrendChart"></div>
                </div>
                <div class="mapMonitoring">
                    <div class="title">
                        <h4>地图监控</h4>
                    </div>
                </div>
            </div>
            <div class="el_left content_r">
                <div class="content_r_t">
                    <!-- 量 -->
                    <div class="el_left salesVolume">
                        <div class="header">
                            <div class="el_right type">
                                <el-radio-group v-model="amountRadio">
                                    <el-radio :label="0">
                                        混凝土
                                    </el-radio>
                                    <el-radio :label="1">
                                        砂浆
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="total">
                            <div>
                                <h4>本月销量(方)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="monthlySales"></p>
                            </div>
                            <div>
                                <h4>年累计销量(方)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="cumulativeSales"></p>
                            </div>
                        </div>
                        <div class="charts" id="salesVolumeCharts"></div>
                    </div>
                    <!-- 价 -->
                    <div class="el_left price">
                        <div class="header">
                            <div class="el_right type">
                                <el-radio-group v-model="radio2">
                                    <el-radio :label="3">
                                        混凝土
                                    </el-radio>
                                    <el-radio :label="6">
                                        砂浆
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="total">
                            <div>
                                <h4>本月售价(元/方)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="monthlySellingPrice"></p>
                            </div>
                            <div>
                                <h4>本年度均价(元/方)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="annualAveragePrice"></p>
                            </div>
                        </div>
                        <div class="charts" id="priceCharts"></div>
                    </div>
                </div>
                <div class="content_r_c">
                    <!-- 款 -->
                    <div class="account">
                        <div class="chartHead">
                            <h3>应收账款</h3>
                            <h3>应付账款</h3>
                        </div>
                        <div>
                            <div class="total">
                                <div>
                                    <h4>本月实收(万元)：</h4>
                                    <p>2.0848</p>
                                </div>
                                <div>
                                    <h4>本年累计(万元)：</h4>
                                    <p>2.0848</p>
                                </div>
                            </div>
                            <div class="chart" id="receivableChart"></div>
                        </div>
                        <div>
                            <div class="total">
                                <div>
                                    <h4>实际采购(万元)：</h4>
                                    <p>2.0848</p>
                                </div>
                                <div>
                                    <h4>本年累计(万元)：</h4>
                                    <p>2.0848</p>
                                </div>
                            </div>
                            <div class="chart" id="copeWithChart"></div>
                        </div>
                    </div>
                </div>
                <div class="content_r_b">
                    <!-- 本 -->
                    <div class="el_left cost">
                        <div class="header">
                            <div class="el_right type">
                                <el-radio-group v-model="radio2">
                                    <el-radio :label="3">
                                        混凝土
                                    </el-radio>
                                    <el-radio :label="6">
                                        砂浆
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="total">
                            <div>
                                <h4>原料成本(万元)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="rawMaterialCost"></p>
                            </div>
                            <div>
                                <h4>年度累计(万元)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="cumulativeCost"></p>
                            </div>
                        </div>
                        <div class="charts" id="costCharts"></div>
                    </div>
                    <!-- 利 -->
                    <div class="el_left profit">
                        <div class="header">
                            <div class="el_right type">
                                <el-radio-group v-model="radio2">
                                    <el-radio :label="3">
                                        混凝土
                                    </el-radio>
                                    <el-radio :label="6">
                                        砂浆
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="total">
                            <div>
                                <h4>本月毛利率(%)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="monthlyGrossProfitRate"></p>
                            </div>
                            <div>
                                <h4>平均毛利率(%)：</h4>
                                <p class="scrollNumbers scrollNumbers1" id="averageGrossMargin"></p>
                            </div>
                        </div>
                        <div class="charts" id="profitCharts"></div>
                    </div>
                </div>
            </div>
        </section>
        <el-dialog
            title="按标号下钻"
            :visible.sync="centerDialogVisible"
            center
            @open="openEject"
        >
            <i class="icon"></i>
            <div class="table">
                <table>
                    <tbody>
                        <tr>
                            <th>C15</th>
                            <th>C20</th>
                            <th>C25</th>
                            <th>C30</th>
                            <th>C35</th>
                            <th>C40</th>
                            <th>C45</th>
                            <th>C50</th>
                            <th>C55</th>
                        </tr>
                        <tr>
                            <td>20px</td>
                            <td>1.35</td>
                            <td>0.80</td>
                            <td>8.14</td>
                            <td>4.72</td>
                            <td>0.84</td>
                            <td>0.43</td>
                            <td>0.25</td>
                            <td>0.01</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="charts" id="ejectCharts"></div>
        </el-dialog>
    </div>
</template>

<script>
import './css/enterpriseScreen_dy.styl';
import back from './../EBCHome/back';
import {
    drawPictorialBar,
    lineColumn,
} from './../../assets/js/charts';
import { DigitRoll } from './../../assets/js/charts/digitRoll.js';

export default {
    // 领导驾驶舱
    name: 'leading-cockpit',
    components: { back },
    props: {},
    data() {
        return {
            // 日均销量，售价类型  0混泥土，1砂浆
            dailyDataRadio: 0,
            // 变化趋势类型，1 近一周数据 2 当月数据
            trendsType: 1,
            // 每日数据时间
            dailyDataTime: '2020-05-26',
            // 每日数据集合
            dailyData: {},
            // 年
            Year: '',
            // 月
            Month: '',
            // 量，默认混泥土
            amountRadio: 0,
            radio2: 3,
            centerDialogVisible: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.Year = new Date().getFullYear();
        this.Month = new Date().getMonth() + 1;
    },
    mounted() {
        // 日销量
        this.electronicNumber('#dailySalesVolume');
        // 日均价
        this.electronicNumber('#averageDailyPrice');


        // 本月售价
        this.electronicNumber('#monthlySellingPrice');
        // 本年度均价
        this.electronicNumber('#annualAveragePrice');
        // 原料成本
        this.electronicNumber('#rawMaterialCost');
        // 年度累计
        this.electronicNumber('#cumulativeCost');
        // 本月毛利率
        this.electronicNumber('#monthlyGrossProfitRate');
        // 平均毛利率
        this.electronicNumber('#averageGrossMargin');
    },
    destroyed() { },
    methods: {
        // 打开弹出层
        openEject() {
            setTimeout(function () {
                drawPictorialBar('ejectCharts');
            }, 0);
        },
        // 初始化页面
        init() {
            // 初始化销量数据
            this.initAmount();
        },
        // 获取每日数据
        getDailyData() {
            this.$axios
                .get('/interfaceApi/report/largescreen/day/' + this.dailyDataTime + '/' + this.dailyDataRadio )
                .then(res => {
                    if (res) {
                        this.dailyDatahandle(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 每日数据处理
        dailyDatahandle(data) {
            // 日销量
            this.electronicNumber('#dailySalesVolume', data.rxl_avg);
            // 日均价
            this.electronicNumber('#averageDailyPrice', data.rjj_avg);
            const dailyData = {
                xAxis: {
                    data: [],
                },
                yAxis: {
                    data: ['日均价', '日销量'],
                },
                series: {
                    data: [ {
                        type: 'bar',
                        name: '日销量',
                        data: [],
                    }, {
                        type: 'line',
                        name: '日均价',
                        data: [],
                    }],
                },
            };
            data.data.forEach(item => {
                dailyData.xAxis.data.push(item.czmc);
                dailyData.series.data[0].data.push(item.rxl);
                dailyData.series.data[1].data.push(item.rjj);
            });
            lineColumn('dailySalesVolumeChart', dailyData);
        },
        // 初始化销量数据
        initAmount() {
            // 本月销量
            this.getTotal('#monthlySales');
            // 年累计销量
            this.getTotal('#cumulativeSales');

            // 本月销量
            this.electronicNumber('#monthlySales');
            // 年累计销量
            this.electronicNumber('#cumulativeSales');
        },
        // 获取头部数量数据
        getTotal() {
            this.$axios
                .get('/interfaceApi/report/largescreen/qty/tj/' + this.Year + '/' + this.Month + '/' + this.amountRadio)
                .then(res => {
                    if (this.amountRadio === 0) {
                        this.hntTotal = res;
                    } else {
                        this.sjTotal = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取统计图数据
        getChartData() {
            this.$axios
                .get('/interfaceApi/report/largescreen/qty/' + this.Year + '/' + this.Month + '/' + this.amountRadio)
                .then(res => {
                    if (this.amountRadio === 0) {
                        this.hntData = res;
                        // this.hntChart();
                    } else {
                        this.sjData = res;
                        // this.sjChart();
                    }

                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 格式化电子数字
        electronicNumber(obj, nub) {
            if (!nub) {
                nub = 0.0;
            }
            new DigitRoll({
                container: obj,
            }).roll(nub);
        },
    },
};
</script>
<style lang="stylus" scoped></style>